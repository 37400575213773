import { useState, PropsWithChildren, ReactNode } from 'react'
import { User } from '@/types'
import { usePage } from '@inertiajs/react'
import {
    Navbar,
    NavbarBrand,
    NavbarMenuToggle,
    NavbarMenuItem,
    NavbarMenu,
    NavbarContent,
    NavbarItem,
    Button,
    Input,
    DropdownItem,
    DropdownTrigger,
    Dropdown,
    DropdownMenu,
    Avatar,
    Link
} from '@nextui-org/react'
import { Link as InertiaLink, InertiaLinkProps } from '@inertiajs/react'
import ResponsiveNavLink from '@/components/ResponsiveNavLink'
import Footer from '@/components/ui/shared/footer'
import CookieConsent, { Cookies } from 'react-cookie-consent'
import { router } from '@inertiajs/react'

import ApplicationLogo from '@/components/ApplicationLogo'
// @ts-ignore
import * as Klaro from 'klaro/dist/klaro-no-css'
import klaroConfig from '@/json/klaro-config.js'
import { trackpageVisit } from '@/lib/events'

// Initialize Klaro!
Klaro.setup(klaroConfig)
// klaro.show()

interface IMenuItem {
    key: string
    href: string
    label: string
    requiresAuth: boolean
}

router.on('navigate', event => {
    console.log(`Navigated to ${event.detail.page.url}`)
    trackpageVisit(event.detail.page.url)
})

const menuItems = ['Profile', 'My Settings', 'Log Out']
const menuItemsCollection: IMenuItem[] = [
    {
        key: 'profile',
        href: '/profile',
        requiresAuth: true,
        label: 'Profile'
    },
    // {
    //     key: 'giveaway-create',
    //     href: '/giveaway/create',
    //     requiresAuth: false,
    //     label: 'Create Giveaway'
    // },
    {
        key: 'logout',
        href: '/logout',
        requiresAuth: true,
        label: 'Log Out'
    }
]

const GeneralLayout = ({ children }: PropsWithChildren<{}>) => {
    const [showingNavigationDropdown, setShowingNavigationDropdown] =
        useState(false)
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const { auth, appName }: any = usePage().props
    return (
        <div className='light dark:dark bg-background text-foreground'>
            <Navbar
                isBordered
                isMenuOpen={isMenuOpen}
                onMenuOpenChange={setIsMenuOpen}
            >
                {/* mobile hamburger */}
                <NavbarContent className='sm:hidden' justify='start'>
                    <NavbarMenuToggle
                        aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
                    />
                </NavbarContent>

                {/* mobile brand */}

                <NavbarContent className='sm:hidden pr-3' justify='center'>
                    <NavbarBrand>
                        <InertiaLink href='/'>
                            <ApplicationLogo className='block h-9 w-auto fill-current text-foreground-800 mr-2' />
                        </InertiaLink>
                        <p className='font-bold text-small text-inherit'>
                            {appName?.toString()}@
                        </p>
                    </NavbarBrand>
                </NavbarContent>

                {/* nav content for desktop */}
                <NavbarContent
                    className='hidden sm:flex gap-4'
                    justify='center'
                >
                    <NavbarBrand>
                        <Link href='/'>
                            <ApplicationLogo className='block h-9 w-auto fill-current text-foreground-800 mr-2' />
                        </Link>
                        <p className='font-bold text-small text-inherit'>
                            {appName?.toString()}
                        </p>
                    </NavbarBrand>
                    <NavbarItem>
                        <Link color='foreground' href='/contact-us'>
                            Contact Us
                        </Link>
                    </NavbarItem>
                    {/* <NavbarItem isActive>
                        <Link href='#' aria-current='page'>
                            How it works
                        </Link>
                    </NavbarItem> */}
                </NavbarContent>
                {/* loggded in user menu desktop */}
                <NavbarContent
                    justify='end'
                    className='hidden sm:flex gap-4 bg-background'
                >
                    {(auth?.user as User) ? (
                        <Dropdown
                            placement='bottom-end'
                            className='bg-background'
                        >
                            <DropdownTrigger>
                                <Avatar
                                    isBordered
                                    as='button'
                                    className='transition-transform'
                                    color='secondary'
                                    name={auth?.user?.userName || ''}
                                    size='sm'
                                    src={
                                        auth?.user?.avatar ||
                                        '/images/avatar2.png'
                                    }
                                />
                            </DropdownTrigger>
                            <DropdownMenu
                                items={menuItemsCollection}
                                color='primary'
                                aria-label='Navigation Menu'
                                variant='flat'
                                className='bg-background'
                            >
                                {item => {
                                    if (item.key === 'logout') {
                                        return (
                                            <DropdownItem
                                                key='logout'
                                                color='danger'
                                            >
                                                <InertiaLink
                                                    method='post'
                                                    href={route('logout')}
                                                >
                                                    Log Out
                                                </InertiaLink>
                                            </DropdownItem>
                                        )
                                    }
                                    return (
                                        <DropdownItem
                                            key={item.key}
                                            color={
                                                item.key === 'delete'
                                                    ? 'danger'
                                                    : 'default'
                                            }
                                            className={
                                                item.key === 'delete'
                                                    ? 'text-danger'
                                                    : ''
                                            }
                                        >
                                            <InertiaLink href={item.href}>
                                                {item.label}
                                            </InertiaLink>
                                        </DropdownItem>
                                    )
                                }}
                            </DropdownMenu>
                        </Dropdown>
                    ) : (
                        <div className='flex gap-4'>
                            <NavbarItem className='hidden lg:flex'>
                                <Link color='secondary' href='/login'>
                                    Login
                                </Link>
                            </NavbarItem>
                            <NavbarItem>
                                <Button
                                    as={Link}
                                    color='success'
                                    href='/register'
                                    variant='solid'
                                >
                                    Sign Up
                                </Button>
                            </NavbarItem>
                        </div>
                    )}
                </NavbarContent>

                <NavbarMenu>
                    {menuItemsCollection.map((item, index) => {
                        if (!auth?.user && item?.requiresAuth) {
                            return null
                        }
                        if (item?.key === 'logout') {
                            return (
                                <InertiaLink
                                    key={`key-${index}-${item.key}`}
                                    method='post'
                                    href={route('logout')}
                                >
                                    Log Out
                                </InertiaLink>
                            )
                        }
                        return (
                            <NavbarMenuItem key={`${item?.key}-${index}`}>
                                <Link
                                    className='w-full'
                                    color={
                                        index === 2
                                            ? 'warning'
                                            : index ===
                                              menuItemsCollection.length - 1
                                            ? 'danger'
                                            : 'foreground'
                                    }
                                    href={item?.href}
                                    size='lg'
                                >
                                    {item?.label}
                                </Link>
                            </NavbarMenuItem>
                        )
                    })}
                </NavbarMenu>
            </Navbar>
            <main className='max-w-7xlx mx-autox py-6x px-4x sm:px-6x lg:px-8x'>
                {children}
            </main>
            <div className='bg-blue-200'>
                {/* <CookieConsent
                    location='bottom'
                    buttonText='Agree'
                    declineButtonText='Decline'
                    cookieName='cookieConsent'
                    ButtonComponent={({ children, ...rest }: any) => {
                        console.log('o,ega', children)
                        return (
                            <Button color='primary' {...rest}>
                                {children}
                            </Button>
                        )
                    }}
                    containerClasses='!bg-primary'
                    buttonClasses=''
                    declineButtonClasses=''
                    contentClasses=''
                    expires={150}
                    onAccept={() => {}}
                >
                    We use cookies to personalize content and ads, to provide
                    social media features, and to analyze our traffic.
                </CookieConsent> */}
            </div>

            <Footer />
        </div>
    )
}

export default GeneralLayout
